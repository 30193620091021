<template>
  <div class="page">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
    >
      <template #searchSlot>
        <v-input label="姓名" v-model="searchParam.realName" />
        <v-input label="用户手机号码" v-model="searchParam.mobileNum" />
        <v-select
          clearable
          :options="siTopOps"
          v-model="searchParam.isPartyMember"
          @change="$refs.list.search()"
          label="是否党员"
        />
        <v-select
          clearable
          :options="siTopOps"
          v-model="searchParam.isCadre"
          @change="$refs.list.search()"
          label="是否干部"
        />
        <v-select
          clearable
          :options="siTopOps"
          v-model="searchParam.isVillagerRepresentatives"
          @change="$refs.list.search()"
          label="是否村民代表"
        />
      </template>
      <template #operateSlot="scope">
        <v-button
          v-if="scope.row.auditStatus == 0"
          text="审核"
          type="text"
          @click="examine(scope.row)"
        />
        <v-button v-else text="已审核" disabled type="text" />
      </template>
    </v-list>
    <v-dialog
      title="人员审核"
      v-model="showDialog"
      width="35%"
      @confirm="submit"
    >
      <el-form label-position="right" label-width="120px">
        <el-form-item label="姓名">
          <v-input disabled v-model="form.realName" />
        </el-form-item>
        <el-form-item label="联系方式">
          <v-input disabled v-model="form.mobileNum" />
        </el-form-item>
        <el-form-item label="身份证号">
          <v-input disabled v-model="form.idCardNum" />
        </el-form-item>
        <el-form-item label="照片" v-if="form.pictures">
          <v-upload :limit="1" :imgUrls.sync="form.pictures" disabled tips="" />
        </el-form-item>
        <el-form-item label="是否为党员">
          <v-radio
            :radioObj="isTopObj"
            v-model="form.isPartyMember"
            disabled
          ></v-radio>
        </el-form-item>
        <el-form-item label="是否为干部">
          <v-radio
            :radioObj="isTopObj"
            v-model="form.isCadre"
            disabled
          ></v-radio>
        </el-form-item>
        <el-form-item label="是否为村民代表">
          <v-radio
            :radioObj="isTopObj"
            v-model="form.isVillagerRepresentatives"
            disabled
          ></v-radio>
        </el-form-item>
        <el-form-item label="职务">
          <v-input v-model="form.post" disabled />
        </el-form-item>
        <el-form-item label="审核">
          <v-select clearable v-model="status" :options="statusObj" />
        </el-form-item>
        <el-form-item label="备注" v-if="status == 2">
          <v-input type="textarea" v-model="message" />
        </el-form-item>
      </el-form>
    </v-dialog>
  </div>
</template>

<script>
import {
  setStatusOps,
  isTopMap,
  setIsTopOps,
  statusMap,
  kindsListMap,
  kindsList,
  secKindsList,
} from "./map.js";
import {
  getUserSubjectAuditInfoListUrl,
  examineUserSubjectAuditInfoUrl,
} from "./api.js";
export default {
  name: "teamManagement",
  data() {
    return {
      statusObj: [
        {
          value: 2,
          label: "未通过",
        },
        {
          value: 1,
          label: "通过",
        },
      ],
      isTopObj: [
        {
          name: 0,
          value: "否",
        },
        {
          name: 1,
          value: "是",
        },
      ],
      showDialog: false,
      siTopOps: setIsTopOps(),
      searchParam: {
        realName: null,
        mobileNum: null,
        isPartyMember: null,
        isCadre: null,
        isVillagerRepresentatives: null,
      },
      tableUrl: getUserSubjectAuditInfoListUrl,
      headers: [
        {
          prop: "realName",
          label: "姓名",
        },
        {
          prop: "mobileNum",
          label: "联系方式",
        },
        {
          prop: "idCardNum",
          label: "身份证",
        },
        {
          prop: "isPartyMember",
          label: "是否为党员",
          formatter: (row, prop) => {
            return isTopMap[row[prop]] || "--";
          },
        },
        {
          prop: "isCadre",
          label: "是否为干部",
          formatter: (row, prop) => {
            return isTopMap[row[prop]] || "--";
          },
        },
        {
          prop: "post",
          label: "职务",
        },
        {
          prop: "isVillagerRepresentatives",
          label: "是否为村民代表",
          formatter: (row, prop) => {
            return isTopMap[row[prop]] || "--";
          },
        },
        {
          prop: "createTime",
          label: "申请时间",
        },
      ],
      form: {},
      status: null,
      message: null,
    };
  },
  methods: {
    examine(item) {
      this.showDialog = true;
      this.form.pictures = null;
      this.form = item;
      this.status = null;
      this.message = null;
    },
    submit() {
      if (String(this.status) != "1" && String(this.status) != "2") {
        this.$message("请选择审核结果");
        return;
      }
      if (String(this.status) == "2" && !this.message) {
        this.$message("请填写备注");
        return;
      }
      let params = {
        id: this.form.id,
        auditStatus: this.status,
        auditRemarks: this.message,
        auditUser: this.$store.state.app.userInfo.userId,
      };
      this.$axios
        .post(`${examineUserSubjectAuditInfoUrl}`, params)
        .then((res) => {
          if (res.code == 200) {
            this.showDialog = false;
            this.$message.success("操作成功！");
            this.$refs.list.search();
          }
        });
    },
  },
};
</script>

<style scoped lang="less">
.page {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
</style>
